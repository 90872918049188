import React from "react"
import {Link} from 'gatsby'
import '../styles/pagination.css'

class Pagination extends React.Component {
  constructor(props) {
    super(props);

      this.initialState = {
        slug: props.slug,
        current: props.page, 
        last: props.pagesCount,
        prevPage: props.prevPage,
        nextPage: props.nextPage,
        width: 2
      };
      this.state = this.initialState;
      this.buttons = this.getButtons();
  }



  getButtons = () => {
    const left = this.state.current - this.state.width;
    const right = this.state.current + this.state.width + 1;
    const range = [];
    const rangeWithDots = [];

    const isFirst = this.state.current === 1
    const isLast = this.state.current === this.state.last
    let cn;
    let l;

    function getLink(page, slug, cn, label){

        if(page === 1){
          return <li><Link className={`${cn}`} to={`/${slug}/`}>{`${label}`}</Link></li>
        }else{
          return <li><Link className={`${cn}`} to={`/${slug}/${page}`}>{`${label}`}</Link></li>
        }

    }

    if(!isFirst){
      rangeWithDots.push(getLink(this.state.current-1, this.state.slug, 'non-active', '←'))
    }

    for (let i = 1; i <= this.state.last; i += 1) {
      if (i === 1 || i === this.state.last || (i >= left && i <= right)) {
        range.push(i);
      } else if (i < left) {
        i = left - 1;
      } else if (i > right) {
        range.push(this.state.last);
        break;
      }
    }

    range.forEach(i => {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push('...');
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      if(i === this.state.current){
        cn = 'active'
      } else {
        cn = 'non-active'
      }
      rangeWithDots.push(getLink(i, this.state.slug, cn, i));
      l = i;
    });

    if(!isLast){
      rangeWithDots.push(getLink(this.state.current+1, this.state.slug, 'non-active', '→'))
    }

  return rangeWithDots;
  };

  render() {

    //console.log(this.props.family)

    return (
      <div className="pagination">
        <ul>{this.buttons}</ul>
      </div>
    )
  }
}

export default Pagination