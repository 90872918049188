import {Link} from 'gatsby'
import React from 'react'
import {getQuoteUrl, leadingZeros} from '../lib/helpers'
import PortableText from './portableText'

import '../styles/quote-archive.css'

function QuoteArchive (props) {

  console.log(props)

  const {_rawDescription} = props

  return (

    <div className="quote-archive-item width-12-12-m">

      <div class="catalog-number">
        <p><Link to={getQuoteUrl(props.catalogNumber)}>{leadingZeros(props.catalogNumber)}</Link></p>
      </div>
      <div class="quote">
      {_rawDescription && (
        <div className="description-holder">
          {_rawDescription && <PortableText blocks={_rawDescription} />}
        </div>
      )}

      {props.source && (
        <div className="source-holder">
          {props.source && (
            <p class="source">–{props.source}</p>
          )}
        </div>
      )}

      {props.links &&
        props.links.map(link => (
        <p class="quote-link">
          <a target="_blank" rel="noreferrer" title={link.title} href={link.url}>
            {link.title}
          </a>
        </p>    
        ))
      }

      </div>

    </div>
  )
}

export default QuoteArchive