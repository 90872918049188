import React from 'react'
import QuoteArchive from './quote-archive'

import '../styles/quote-archive-list.css'

function QuoteArchiveList (props) {

  return (
    <div className="wrapper">
    <div className="quote-archive-list">
      {props.title && <h3>{props.title}</h3>}
      <div className="grid quote-archive-holder">
        {props.nodes &&
          props.nodes.map(node => (
              <QuoteArchive {...node} />
          ))}
      </div>
    </div>
    </div>
  )
}

QuoteArchiveList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default QuoteArchiveList