import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  filterOutCatsWithoutParents
} from '../lib/helpers'
import QuoteArchiveList from '../components/quote-archive-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Pagination from '../components/pagination';

export const query = graphql`

  query QuoteQuery($skip: Int!, $limit: Int!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }
    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }
    quotes: allSanityQuote(
      sort: { fields: [catalogNumber], order: DESC }
      filter: { catalogNumber: { ne: null }, publishedAt: { ne: null } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          publishedAt
          source
          title
          catalogNumber
          links {
            title
            url
          }
          _rawDescription(resolveReferences: {maxDepth: 5})
        }
      }
    }

  }
`

const Quotes = props => {
  const {data, errors} = props

  //console.log('omg')
  //console.log(props)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  const quoteNodes = (data || {}).quotes
    ? mapEdgesToNodes(data.quotes)
      .filter(filterOutDocsPublishedInTheFuture)
    : []
  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  const { currentPage, numPages } = props.pageContext
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()


  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink}>
      <SEO
        title={site.title}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>

        {quoteNodes && (
          <QuoteArchiveList
            title='Quotes'
            nodes={quoteNodes}
            browseMoreHref='/quotes/'
          />
        )}

          <Pagination
            slug= 'quotes'
            page={currentPage}
            pagesCount={numPages}
            prevPage={prevPage}
            nextPage={nextPage}
          />

      </Container>
    </Layout>
  )
}

export default Quotes